$clientColor: #000000;
$md: 768px !default;

.ItemsSection {
  .imageItems {
    border-radius: 100px;
    cursor: pointer;
    width: 85px;
    aspect-ratio: 1;
    object-fit: cover;
    object-position: center;

    &.colors {
      border-radius: initial;
    }

    &.overlayTexture {
      z-index: 1;
      position: absolute;
    }
  }

  .imageCard {
    cursor: pointer;
    width: 80px;
  }

  .imagePricePosition {
    position: absolute;
    right: -13px;
    top: -10px;
    // mobile
    @media screen and (orientation: landscape) {
      right: -13px;
      top: -10px;
      width: 35px;
    }
  }

  .rounded-button {
    width: max-content;
    background-color: #f5f6f4;
    border-radius: 6px;
    border: none;
    box-shadow: 0 5px 5px 2px #8c8c8c;
    color: $clientColor;
    padding: 5px 10px 5px 10px;
  }

  .cardItem {
    cursor: pointer;
    box-shadow: 0 1px 10px 3px #8c8c8c;
    border: none !important;
    height: 125px;
    width: 100px;
    margin-top: 2%;

    &:hover {
      @media (min-width: $md) and (orientation: landscape) {
        box-shadow: 1px 1px 2px 0px #999999;
        opacity: 1 !important;
      }
    }

    .card-img-top {
      margin-top: 15%;
    }

    h4 {
      color: white;
      font-family: "impact", sans-serif;
    }

    .imgFrame {
      width: 100%;
      position: relative;
      border-radius: 0.4rem 0.4rem 0.4rem 0.4rem;
      border-style: solid;
      border-color: white;
      background-color: #000000;
      min-height: 70%;

      // used in <ImgWithFallback />
      picture {
        justify-content: center;
        display: flex;
      }

      &.zoomed {
        overflow: hidden;
        justify-content: center;

        img {
          //width: 200%;
          flex-shrink: 0;
        }
      }

      @media screen and (min-width: $md) {
        height: 162px;
      }
    }

    &.disabled {
      opacity: 0.25;
    }

    .card-title {
      font-size: 1.2em;
    }

    .card-text {
      font-size: 0.8em;
    }
  }

  .section-title {
    text-align: left;
    text-transform: uppercase;
  }
}

.new-icon {
  left: -14px;
  position: absolute;
  top: -7px;
  width: 40px;
  z-index: 10;
  transform: rotate(-16deg);
}
